<template>
  <div class="studio">
    <div class="ux__menu">
      <!-- <b-slider
        size="is-medium"
        :min="1"
        :max="100"
        v-model="texture_repeat"
        @input="textureRepeatModify()"
      >
      </b-slider> -->
    </div>

    <div
      id="scene"
      :class="{ over: isDragOver }"
      @dragover.prevent="dragOver"
      @dragleave.prevent="dragLeave"
      @drop.prevent="drop($event)"
    ></div>
    <div class="ux__sidebar">
      <hex-color-picker
        class="color-picker__module"
        :color="texture_color"
        @color-changed="handleColorChanged"
      ></hex-color-picker>

      <b-input v-model="texture_color" onclick="this.select()"></b-input>

      <b-slider
        size="is-medium"
        :min="1"
        :max="100"
        v-model="texture_repeat"
        @input="textureRepeatModify()"
      >
      </b-slider>
      <b-slider
        size="is-medium"
        :min="1"
        :max="360"
        v-model="texture_rotate"
        @input="textureRotateModify()"
      >
      </b-slider>
      <b-slider
        size="is-medium"
        :min="1.0"
        :max="1400.0"
        v-model="texture_x"
        @input="textureTranslate()"
      >
      </b-slider>
      <b-slider
        size="is-medium"
        :min="1.0"
        :max="1400.0"
        v-model="texture_y"
        @input="textureTranslate()"
      >
      </b-slider>
    </div>
    <div class="ux__tools">
      <ul>
        <!--li>studio</li>
                <li @click="operations()">operations</li>
                <li @click="modelExport()">export</li>
                <li @click="modelReset()">reset</li>
                <li @click="modelSave()">save</li-->
        <li @click="modelDownload()" class="button">download</li>
      </ul>
    </div>
  </div>
</template>

<script>
//import Layout from "@abstraktion/abstraktion-lib/visualize/layout/Layout";
//import Tool from "@abstraktion/abstraktion-lib/visualize/tool/Tool";

import Layout from "@abstraktion/abstraktion-lib/visualize/layout/Layout";
import Tool from "@abstraktion/abstraktion-lib/visualize/tool/Tool";

// demo

import ii from "../assets/model/shirt/woman_shirt.jpg";
import im from "../assets/model/shirt/woman_shirt_mask_full.png";
import it from "../assets/model/shirt/woman_shirt_texture_1.jpg";
//import it from '../assets/model/shirt/abstraktion_square.png'
import "vanilla-colorful";

export default {
  name: "Studio",
  components: {},
  data() {
    return {
      layout: null,
      tool: null,
      json_export: null,

      texture_repeat: 7,
      model_u: 20,
      model_v: 20,
      dot_size: 10,
      grid_color: 0xff0000,

      isDragOver: false,
      grid_display: false,

      // into localstorage
      texture_color: "ffffff",
      texture_rotate: 0,
      texture_x: 0,
      texture_y: 0,
    };
  },
  mounted() {
    this.init();
    this.initLogic();
  },
  created() {
    //this.layout.model.getGrid(0).lineUAdd(1)
  },
  methods: {
    handleColorChanged(e) {
      if (this.layout) {
        this.texture_color = e.target.color.replace("#", "");
        let hextest = new RegExp("^#([a-fA-F0-9]){3}$|[a-fA-F0-9]{6}$");

        if (hextest.test(this.texture_color) && this.layout) {
          this.layout.getModel().setColor("#" + this.texture_color);
          try {
            localStorage.setItem("texture_color", this.texture_color);
          } catch (e) {
            console.log(" error ", e);
          }
        }
      }
    },
    textureRepeatModify() {
      if (this.layout) {
        this.layout.getModel().setRepeat(this.texture_repeat);

        try {
          localStorage.setItem("model_texture_repeat", this.texture_repeat);
        } catch (e) {
          console.log(" error ", e);
        }
      }
    },
    textureRotateModify() {
      if (this.layout) {
        this.layout.getModel().setAngle((Math.PI / 180) * this.texture_rotate);

        try {
          localStorage.setItem("texture_rotate", this.texture_rotate);
        } catch (e) {
          console.log(" error ", e);
        }
      }
    },
    textureTranslate() {
      if (this.layout) {
        this.layout
          .getModel()
          .setTranslateTexture(this.texture_x / 1400, this.texture_y / 1400);
        try {
          localStorage.setItem("texture_x", this.texture_x);
          localStorage.setItem("texture_y", this.texture_y);
        } catch (e) {
          console.log(" error ", e);
        }
      }
    },

    gridDisplay() {
      this.tool.displayDotGrid(!this.grid_display);
    },

    dragOver() {
      //console.log('dragOver')
      this.isDragOver = true;
    },

    dragLeave() {
      //console.log('dragLeave')
      this.isDragOver = !true;
    },

    drop(e) {
      //console.log('drop',e)

      let files = e.dataTransfer.files;

      let file = files[0];

      let reader = new FileReader();
      reader.onload = (f) => {
        // f.target.result contains the base64 encoding of the image
        let src = f.target.result;

        // set texture
        this.layout.setTexture(src, this.texture_repeat);

        // localstorage save
        try {
          localStorage.setItem("model_texture", src);
          src = null;
        } catch (e) {
          console.log(" error : texture size exceeds ", e);
          src = null;
        }
      };
      reader.readAsDataURL(file);

      this.isDragOver = !true;
    },

    modelDownload() {
      // https://codepen.io/looeee/pen/GdQpbd

      if (this.grid_display) this.gridDisplay(false); // not working

      var imgData;

      var saveFile = function (strData, filename) {
        var link = document.createElement("a");
        if (typeof link.download === "string") {
          document.body.appendChild(link); //Firefox requires the link to be in the body
          link.download = filename;
          link.href = strData;
          link.click();
          document.body.removeChild(link); //remove the link when done
        } else {
          //location.replace(uri)
        }
      };

      try {
        const strMime = "image/jpeg";

        imgData = this.layout.renderer.domElement.toDataURL(strMime);

        saveFile(imgData.replace(strMime, strMime), "abstraktion.jpg");
      } catch (e) {
        console.log(e);
        return;
      }

      if (this.grid_display) this.gridDisplay(true);
    },

    operations() {
      console.log("operations");

      //this.layout.model.getGrid(0).rotate(45)
      //this.layout.model.getGrid(0).lineUAdd(1)

      this.grid_display = !this.grid_display;
      this.tool.displayGrid(this.grid_display);
    },

    modelSave() {
      console.log("modelSave");

      localStorage.setItem(
        "model_grid",
        JSON.stringify(this.layout.model.getGrid(0).export())
      );
    },

    modelReset() {
      localStorage.removeItem("model_picture");
      localStorage.removeItem("model_mask");
      localStorage.removeItem("model_grid");
      localStorage.removeItem("model_texture");
    },

    init() {
      this.tool = new Tool();
      this.tool.displayDotGrid(this.grid_display);

      // repair
      //this.tool.setLineU()
      //this.tool.setLineV()

      let layout = new Layout({
        tool: this.tool,
        div_id: "scene",
        is_3d: false,
        debug: !true,
        helpers: !true,
        dot_display: true,
        background: {
          color: 0xffffff,
        },
      });

      // image in localstorage ?

      let model_picture, model_mask, model_texture, model_grid;

      if (localStorage.getItem("model_texture_repeat") !== null) {
        this.texture_repeat = parseInt(
          localStorage.getItem("model_texture_repeat")
        );
        layout.model.setRepeat(this.texture_repeat);
      }

      if (localStorage.getItem("model_picture") !== null) {
        model_picture = localStorage.getItem("model_picture");
      } else {
        model_picture = ii;
      }
      if (localStorage.getItem("model_mask") !== null) {
        model_mask = localStorage.getItem("model_mask");
      } else {
        model_mask = im;
      }
      if (localStorage.getItem("model_texture") !== null) {
        model_texture = localStorage.getItem("model_texture");
      } else {
        model_texture = it;
      }
      if (localStorage.getItem("model_grid") !== null) {
        model_grid = JSON.parse(localStorage.getItem("model_grid"));
      } else {
        model_grid = null;
      }

      if (localStorage.getItem("texture_color") !== null) {
        this.texture_color = localStorage.getItem("texture_color");
      } else {
        this.texture_color = "ffffff";
      }
      if (localStorage.getItem("texture_rotate") !== null) {
        this.texture_rotate = JSON.parse(
          localStorage.getItem("texture_rotate")
        );
      } else {
        this.texture_rotate = 0;
      }
      if (localStorage.getItem("texture_x") !== null) {
        this.texture_x = JSON.parse(localStorage.getItem("texture_x"));
      } else {
        this.texture_x = 0;
      }
      if (localStorage.getItem("texture_y") !== null) {
        this.texture_y = JSON.parse(localStorage.getItem("texture_y"));
      } else {
        this.texture_y = 0;
      }

      layout.setPicture(model_picture);
      layout.setModel(
        model_mask,
        model_texture,
        {
          dot: {
            color: this.grid_color,
            size: this.dot_size,
          },
          repeat: this.texture_repeat,
          uv: {
            u: this.model_u,
            v: this.model_v,
          },
          import: JSON.parse(localStorage.getItem("model_grid")),
        },
        () => {
          //console.log( 'callback' )

          localStorage.setItem(
            "model_grid",
            JSON.stringify(this.layout.model.getGrid(0).export())
          );

          // apply
          this.layout.getModel().setColor("#" + this.texture_color);
        }
      );

      //console.log(layout);

      window.l = layout; //@debug

      this.layout = layout;
    },

    initLogic() {
      /**
       * mouse interaction
       */

      this.layout.mouse.setMouseDragCallback(() => {
        // if not moving dots
        console.log("cb");
        if (this.layout.controls.enabled) {
          // left click
          if (this.layout.mouse.isClickLeft()) {
            //

            if (this.layout.keyboard.isAlt()) {
              // rotate all

              //this.modelLayerRotate( this.layout.getLayerActive() , this.layout.mouse.dragX()/100 + this.layout.mouse.dragY()/1000 )
              this.layout.model.getGrid(
                this.layout.getLayerActive()
              ).mesh.material.map.rotation =
                this.layout.mouse.dragX() / 100 +
                this.layout.mouse.dragY() / 1000;
            } else {
              if (this.layout.keyboard.isControl()) {
                // rotate material
                //this.modelMaterialRotate( this.layout.getLayerActive() , this.layout.mouse.dragX()/10 + this.layout.mouse.dragY()/100 )
              }

              if (this.layout.keyboard.isShift()) {
                // texture size

                let zy = this.layout.mouse.dragY() / 30;

                if (zy < 0) zy = -zy;

                let zx = this.layout.mouse.dragX() / 1000;

                if (zx < 0) zx = -zx;

                //this.modelTextureSize       (  this.layout.getLayerActive() , zx + zy )
              }

              if (this.layout.keyboard.isNop()) {
                // default
                //this.modelMaterialMove      ( this.layout.getLayerActive() , -this.layout.mouse.dragX()/100 , this.layout.mouse.dragY()/100 )
              }
            }

            //

            //this.modelLayerRepeat       ( 0 , this.layout.mouse.dragY() )
          }
        }
      });
    },

    modelLoad() {
      /*this.layout.model.add(

                    new Mask(
                        model.texture_mask , 1300 , 1450
                    ),

                    new Grid({
                        texture : model.texture_grid,
                        dot: {
                            color: 0xff0000,
                            size: 10
                        },
                        width:      1300,
                        height:     1450,
                        repeat:     10,
                        uv: {
                            u: 10,
                            v: 10,
                        },
                        //import: JSON.parse(verticesJson)
                    })

                )*/
    },
  },
};
</script>

<style lang="scss">
#scene {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all 1s;
  &.over {
    filter: brightness(50%);
  }
}

#export {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  width: 16rem;
  height: 8rem;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 1rem;
  font-size: 0.5rem;
  word-wrap: break-word;
  overflow-y: auto;
  text-align: left;
}

.ux {
  &__menu {
    background-color: rgba(255, 255, 255, 0.8);
    width: 100%;
    //padding: 2rem;

    ul {
      display: flex;
      max-width: 80%;
      margin: 0 auto;
      width: 100%;
      text-align: center;
      height: auto;
      position: absolute;

      li {
        &:first-child {
          font-weight: bold;
        }
        margin-right: 2rem;
        cursor: pointer;
        transition: all 1s;
        &:hover {
          opacity: 0.5;
        }
        margin: 1rem;
      }
    }

    .b-slider {
      margin: 0px;
    }
  }

  &__sidebar {
    background-color: rgba(255, 255, 255, 0);
    transition: all 1s;
    &:hover {
      background-color: rgba(255, 255, 255, 0.85);
    }
    height: 100%;
    min-width: 14em;
    position: fixed;
    right: 0;
    top: 139px;
    padding: 0.75em;

    .b-slider {
      margin: 2em 0;
      background: transparent;
      width: 100%;
    }

    .b-slider-box {
      min-height: 2em;
      width: 100%;
    }

    .b-slider-thumb {
      position: absolute;
      z-index: 1;
      box-sizing: border-box;
      width: 28px !important;
      height: 28px !important;
      transform: translate(-50%, -50%) !important;
      background-color: #fff !important;
      border: 2px solid #fff !important;
      border-radius: 50% !important;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.205) !important;
    }

    .b-slider.is-medium .b-slider-track {
      height: 1px !important;
      transition: all 1000ms;
      &:hover {
        transition: all 1000ms;
        height: 1px !important;
        .b-slider-track {
          border-radius: 0px !important;
        }

        .b-slider-fill {
          background: #ddd !important;
          border-radius: 0px !important;
        }
        //opacity: 0.2;
      }
    }

    .b-slider-box {
      min-height: 1em;
      width: 100%;
    }
  }

  &__tools {
    color: #000;
    position: absolute;
    bottom: 0px;
    padding: 1rem;
    left: 0;
    width: 100%;

    background-color: rgba(255, 255, 255, 0);
    transition: all 1s;
    &:hover {
      background-color: rgba(255, 255, 255, 0.85);
    }

    ul {
      text-transform: uppercase;
      font-size: 1rem;

      li {
        &:first-child {
          font-weight: 400;
        }
        margin-right: 2rem;
        cursor: pointer;
        transition: all 1s;
        &:hover {
          opacity: 0.5;
        }
        margin: 0 1rem;
      }
    }
  }
}
</style>